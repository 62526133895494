import React, { FC } from 'react';

import { IFaqAccordionItemTextComponentProps } from 'components/FaqAccordionItemText/models';
import 'components/FaqAccordionItemText/FaqAccordionItemText.scss';
import { Container } from 'react-bootstrap';

const FaqAccordionItemText: FC<IFaqAccordionItemTextComponentProps> = ({ accordionItemText }) => {
  return (
    <Container
      className="dt-faq-accordion-item-text"
      fluid
      dangerouslySetInnerHTML={{ __html: accordionItemText }}
    />
  );
};

export default FaqAccordionItemText;
