import React, { FC } from 'react';

import { IFaqAccordionItemComponentProps } from 'components/FaqAccordionItem/models';
import 'components/FaqAccordionItem/FaqAccordionItem.scss';

const FaqAccordionItem: FC<IFaqAccordionItemComponentProps> = ({ link, label }) => {
  return (
    <a className="dt-faq-accordion-item" href={link?.[0]?.url ?? '#'}>
      {label}
    </a>
  );
};

export default FaqAccordionItem;
