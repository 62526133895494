import React, { FC } from 'react';
import Accordion from 'react-tiny-accordion';

import Button from 'components/common/Button';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import IconCustom from 'components/common/IconCustom';

import { parseBoolean } from 'utils/parseHelpers';

import { ControledAccordionProps } from './model';
import './ControledAccordion.scss';
import './ControledAccordionOverride.scss';

const ControledAccordion: FC<ControledAccordionProps> = ({ items, selectedIndex, isExpanded }) => {
  const index = selectedIndex
    ? selectedIndex >= items.length
      ? items.length - 1
      : selectedIndex - 1
    : null;

  const isItemsExpanded = parseBoolean(isExpanded);

  return isItemsExpanded ? (
    <div className="gs-accordion--expanded">
      {items.map(({ properties: { answer, question } }) => (
        <Accordion
          key={question}
          className="gs-accordion"
          transitionDuration="300"
          selectedIndex={0}
        >
          <div
            className="gs-accordion__item"
            data-header={
              <Button variant="icon" classes="gs-accordion__question">
                <IconCustom icon="chevron-down" />
                <DangerouslySetInnerHtml html={question} />
              </Button>
            }
          >
            <DangerouslySetInnerHtml html={answer} />
          </div>
        </Accordion>
      ))}
    </div>
  ) : (
    <Accordion
      className="gs-accordion"
      transitionDuration="300"
      {...(index && { selectedIndex: index })}
    >
      {items.map(({ properties: { answer, question } }) => (
        <div
          className="gs-accordion__item"
          key={question}
          data-header={
            <Button variant="icon" classes="gs-accordion__question">
              <IconCustom icon="chevron-down" />
              <DangerouslySetInnerHtml html={question} />
            </Button>
          }
        >
          <DangerouslySetInnerHtml html={answer} />
        </div>
      ))}
    </Accordion>
  );
};

export default ControledAccordion;
