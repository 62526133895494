import React, { FC } from 'react';
import { GlobalContactForm } from '@phx-husky/global-contact-form';
import { IContactForm } from './models';
import './ContactForm.scss';

const ContactForm: FC<IContactForm> = ({ url }) => {
  return (
    <div className="container-fluid">
      <GlobalContactForm title="Contact form" id="phx-global-form-gatsby" src={url} />
    </div>
  );
};

export default ContactForm;
