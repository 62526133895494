import React, { FC } from 'react';

import {
  IFaqAccordionItemStructure,
  IFaqSectionComponentProps,
} from 'components/FaqSection/models';
import 'components/FaqSection/FaqSection.scss';
import { Container } from 'react-bootstrap';
import FaqAccordionItem from 'components/FaqAccordionItem';

const FaqSection: FC<IFaqSectionComponentProps> = ({ accordion, text }) => {
  return (
    <Container className="dt-faq-section" fluid>
      {text && <div className="dt-faq-section__text" dangerouslySetInnerHTML={{ __html: text }} />}
      {accordion && (
        <div>
          {accordion?.map((item, index) => (
            // eslint-disable-next-line @typescript-eslint/no-use-before-define, react/no-array-index-key
            <FaqSectionItem key={index} {...item} />
          ))}
        </div>
      )}
    </Container>
  );
};

const FaqSectionItem: FC<IFaqAccordionItemStructure> = ({ structure, properties }) => {
  if (structure === 'FAQ Accordion Item') {
    return <FaqAccordionItem {...properties} />;
  }
  return null;
};

export default FaqSection;
