import React, { FC } from 'react';
import classnames from 'classnames';
import Container from 'react-bootstrap/Container';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import { PlainTextProps } from './model';
import './PlainTextBlock.scss';

const PlainTextBlock: FC<PlainTextProps> = ({ content, color, maxWidth }) => {
  const sectionCSSClassNames = classnames('plain-text-block', {
    [`plain-text-block--bg-${color[0]}`]: color?.length,
  });
  const maxContentWidth = maxWidth || 100;

  return content ? (
    <div className={sectionCSSClassNames} data-testid="text-block">
      <Container fluid>
        <DangerouslySetInnerHtml
          style={{ '--width': `${maxContentWidth}%` }}
          className="plain-text-block__description"
          html={content}
        />
      </Container>
    </div>
  ) : null;
};

export default PlainTextBlock;
