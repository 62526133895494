import { TItem } from './model';

const getSchemaItem = ({ properties: { question, answer } }: TItem) => ({
  '@type': 'Question',
  name: question,
  acceptedAnswer: {
    '@type': 'Answer',
    text: answer,
  },
});

const getSchemaScript = (items: TItem[]) => {
  const schemaContent = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: items.map(getSchemaItem),
  };

  const schemaScript = [
    {
      type: 'application/ld+json',
      innerHTML: JSON.stringify(schemaContent),
    },
  ];

  return schemaScript;
};

export default getSchemaScript;
