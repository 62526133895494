import React, { FC } from 'react';
import { graphql } from 'gatsby';
import isEmpty from 'lodash/isEmpty';

import TopBanner from 'components/TopBanner';
import Layout from 'components/Layout';
import BodyRenderer from 'components/common/BodyRenderer';
import WhereToBuy from 'components/WhereToBuy';
import FaqBlock from 'components/FaqBlock';
import PlainTextBlock from 'components/PlainTextBlock';
import ContactForm from 'components/ContactForm';
import StoreFinderRedirect from 'components/StoreFinderRedirect';
import BreadCrumbs from 'components/common/BreadCrumbs';
import './PlainPage.scss';
import FaqAccordionItemText from 'components/FaqAccordionItemText';
import FaqSection from 'components/FaqSection';
import { IPlainPageProps } from './models';

const elements = {
  // TODO milu add new data type
  'Where to buy': ({ properties }, keyId) => <WhereToBuy key={keyId} {...properties} />,
  'FAQ block': ({ properties }, keyId) => <FaqBlock key={keyId} {...properties} />,
  'Text Block': ({ properties }, keyId) => <PlainTextBlock key={keyId} {...properties} />,
  'Contact Form': ({ properties }, keyId) => <ContactForm key={keyId} {...properties} />,
  'Store Finder Redirect': ({ properties }, keyId) => (
    <StoreFinderRedirect key={keyId} {...properties} />
  ),
  'FAQ Section': ({ properties }, keyId) => <FaqSection key={keyId} {...properties} />,
  'Accordion Item Text': ({ properties }, keyId) => (
    <FaqAccordionItemText key={keyId} {...properties} />
  ),
};

const PlainPage: FC<IPlainPageProps> = ({
  data: {
    page: {
      nodes: [pageData],
    },
    siteSettings,
    commonSettings,
    header,
    footer,
  },
  pageContext: { breadCrumbs },
}) => {
  const {
    body,
    seoMetaTitle,
    seoMetaDescription,
    seoMetaKeywords,
    seoExternalHreflangs,
    openGraphImageUrl,
    topBanner,
    showBreadcrumbs,
  } = pageData;

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        openGraphImageUrl,
      }}
      siteSettings={siteSettings}
      commonSettings={commonSettings}
      header={header}
      footer={footer}
    >
      <BreadCrumbs data={breadCrumbs} showBreadcrumbs={showBreadcrumbs} />
      {!isEmpty(topBanner) ? (
        <TopBanner classes="plain-top-banner" {...topBanner?.[0]?.properties} />
      ) : null}
      <BodyRenderer bodyData={body} bodyStructure={elements} />
    </Layout>
  );
};

export const query = graphql`
  query($link: String = "", $lang: String) {
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
    commonSettings(lang: { eq: $lang }) {
      blocks {
        structure
        properties {
          ...FragmentDefaultCommonSettingsProps
        }
      }
    }
    header(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footer(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    page: allPlainPage(filter: { link: { eq: $link } }) {
      nodes {
        seoMetaTitle
        seoMetaDescription
        seoMetaKeywords
        seoExternalHreflangs {
          key
          value
        }
        openGraphImageUrl
        id
        link
        showBreadcrumbs
        topBanner {
          ...FragmentTopBanner
        }
        body {
          ... on TTextBlockStructure {
            structure
            properties {
              content
              maxWidth
              color
            }
          }
          ... on TFAQblockStructure {
            structure
            properties {
              title
              color
              selectedIndex
              isExpanded
              items {
                properties {
                  answer
                  question
                }
              }
              disableSeoSchema
            }
          }
          ... on TAccordionItemTextStructure {
            structure
            properties {
              accordionItemText
            }
          }
          ... on TFAQSectionStructure {
            structure
            properties {
              text
              accordion {
                structure
                properties {
                  label
                  ariaLabel
                  content {
                    structure
                    properties {
                      accordionItemText
                    }
                  }
                  link {
                    url
                    target
                    queryString
                  }
                }
              }
            }
          }
          ... on TWheretobuyStructure {
            structure
            properties {
              color
              title
              listMode
              limit
              limitMobile
              btnMore
              ariaMore
              cardWithCta
              cardCtaLabel
              cards {
                properties {
                  type
                  title
                  link {
                    url
                    target
                    queryString
                  }
                  image {
                    ...FragmentGatsbyImage
                  }
                  imageAlt
                }
              }
            }
          }
          ... on TStoreFinderRedirectStructure {
            structure
            properties {
              ariaButton
              button
              image {
                ...FragmentGatsbyImage
              }
              imageAlt
              label
              queryString
              title
            }
          }
          ... on TContactFormStructure {
            structure
            properties {
              url
            }
          }
        }
      }
    }
  }
`;

export default PlainPage;
