import React, { FC, useCallback } from 'react';
import ExternalLink from 'components/common/ExternalLink';
import GatsbyImage from 'components/common/GatsbyImage';
import classnames from 'classnames';
import { gtmService } from 'services/gtmService';

import IconCustom from 'components/common/IconCustom';

import { IWhereToBuyCardProps } from './model';

const WhereToBuyCard: FC<IWhereToBuyCardProps> = ({
  title,
  type,
  imageAlt,
  image,
  link,
  classes,
  cardWithCta,
  cardCtaLabel,
  retailerClickHandler,
}) => {
  const path = (link?.[0]?.url || '').concat(link?.[0]?.queryString || '');
  const cardCSSClassNames = classnames('where-to-buy__card', {
    [`${classes}`]: classes,
    'where-to-buy__card--with-cta': cardWithCta,
  });

  const handleRetailerClick = useCallback(
    (shopName: string = '') => () => {
      if (typeof retailerClickHandler === 'function') {
        retailerClickHandler(shopName);
      } else {
        gtmService.emitRetailerClick({}, shopName);
      }
    },
    []
  );

  return cardWithCta ? (
    <div className={cardCSSClassNames} data-testid="where-to-buy">
      {image?.fallbackUrl ? (
        <div className="where-to-buy__card-image">
          <GatsbyImage
            objectFit="contain"
            className="where-to-buy__card-image-img"
            fluid={image}
            alt={imageAlt}
          />
        </div>
      ) : null}
      <ExternalLink to={path} target={link?.[0]?.target} className="where-to-buy__card-link">
        <span className="where-to-buy__card-link-text">
          <span>{cardCtaLabel}</span>
          <IconCustom icon="chevron-right" />
        </span>
      </ExternalLink>
    </div>
  ) : (
    <ExternalLink
      to={path}
      target={link?.[0]?.target}
      className={cardCSSClassNames}
      data-testid="where-to-buy"
      onClick={handleRetailerClick(title)}
    >
      <div className="where-to-buy__card-image">
        {image?.fallbackUrl ? (
          <GatsbyImage
            objectFit="contain"
            className="where-to-buy__card-image-img"
            fluid={image}
            alt={imageAlt}
          />
        ) : null}
      </div>
      {title || type ? (
        <div className="where-to-buy__card-text">
          {title ? <strong className="where-to-buy__card-title">{title}</strong> : null}
          {type ? <p className="where-to-buy__type">{type}</p> : null}
        </div>
      ) : null}
    </ExternalLink>
  );
};

export default WhereToBuyCard;
